<template>
  <div class="detalle mx-2">
    <v-row>
      <v-col cols="12">
        <v-card v-if="factura.factura != undefined">
          <v-card-title>
            Factura N° {{ factura.factura.folio | folio }}
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="$router.push('/facturas')"
              class="d-print-none"
              >Lista</v-btn
            >
            <!-- Acciones -->
            <v-menu offset-y :close-on-content-click="false" ref="acciones">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :dark="!loading"
                  :color="!loading ? 'black' : 'gray'"
                  :loading="loading"
                  :disabled="loading"
                  class="ml-2 d-print-none"
                  v-on="on"
                  v-bind="attrs"
                  >Acciones</v-btn
                >
              </template>
              <v-list>
                <v-list-item @click="pdf()">
                  <v-list-item-title>
                    PDF
                  </v-list-item-title>
                  <v-list-item-icon>
                    <v-icon right>mdi-file-pdf-box</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="print()">
                  <v-list-item-title>POS</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon right>mdi-ticket-confirmation</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  ref="submenu_send"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-title>Enviar</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon right>mdi-send</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <v-card width="400">
                    <v-card-title>Enviar Factura</v-card-title>
                    <v-card-text>
                      <div style="font-size: 1.3em">
                        Ingresa la direccion de correo
                      </div>
                      <br />
                      <v-text-field
                        type="email"
                        label="Email"
                        dense
                        rounded
                        outlined
                        v-model="email"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.submenu_send.isActive = false;
                          $refs.acciones.isActive = false;
                        "
                        >Cancelar</v-btn
                      >
                      <v-btn @click="send()" dark color="black">Enviar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <v-list-item @click="notaCredito()">
                  <v-list-item-title>Nota Credito</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon right>mdi-cancel</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-menu
                  v-if="reasingState"
                  :close-on-content-click="false"
                  :nudge-width="200"
                  ref="submenu_reasignar"
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-list-item-title>Reasignar Fecha</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon right>mdi-calendar</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <v-card width="400">
                    <v-card-title>Reasigna la fecha de la factura</v-card-title>
                    <v-card-text>
                      <v-date-picker
                        scrollable
                        v-model="factura.factura.fecha"
                        locale="es-co"
                        color="primary"
                        first-day-of-week="1"
                        full-width
                        :max="max"
                      ></v-date-picker>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.submenu_reasignar.isActive = false;
                          $refs.acciones.isActive = false;
                        "
                        >Cancelar</v-btn
                      >
                      <v-btn @click="reasignarFecha()" dark color="black" :loading="loading_fecha"
                        >Actualizar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-subtitle>
            <strong v-if="factura.header.regimen != 'simplificado'"
              >Responsable de IVA</strong
            >
            <strong v-else>No responsable de IVA</strong>
            <br />
            <small v-if="factura.header.regimen != 'documento'"
              >Regimen {{ factura.header.regimen }}</small
            >
            <small v-else>Regimen comun con documento equivalente</small>
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <p>
                      <strong>
                        Cliente:
                      </strong>
                      {{ factura.factura.cliente }}
                      <br />
                      <strong>
                        CC/NIT:
                      </strong>
                      {{ factura.factura.cc_cliente }}
                      <br />
                      <strong>
                        Dirección:
                      </strong>
                      {{ factura.factura.direccion }}
                      <br />
                      <strong>
                        Teléfono:
                      </strong>
                      {{ factura.factura.telefono }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <p>
                      <strong>
                        Forma de pago:
                      </strong>
                      <span v-if="factura.factura.metodo == 1">CONTADO</span>
                      <span v-else>CREDITO</span>
                      <br />
                      <strong>
                        Fecha factura:
                      </strong>
                      {{ moment(factura.factura.fecha).format("ll") }}
                      <br />
                      <strong>
                        Fecha vencimiento:
                      </strong>
                      {{ moment(factura.factura.vencimiento).format("ll") }}
                      <br />
                      <strong>
                        Observación:
                      </strong>
                      {{ factura.factura.observacion }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Productos -->
              <v-col
                cols="12"
                md="12"
                sm="12"
                v-if="consumo.length > 0 || venta.length > 0"
              >
                <v-card elevation="0" outlined>
                  <v-card-title>Productos</v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Tipo</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Valor Unit</th>
                            <th>Subtotal</th>
                            <th>IVA</th>
                            <th>Total</th>
                            <th>Vendedor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in productos" :key="index">
                            <td>{{ item.categoria }}</td>
                            <td>
                              {{ item.salida }}
                            </td>
                            <td>
                              {{ item.cantidad }}
                            </td>
                            <td>
                              <span v-if="item.categoria == 'consumo'">
                                {{ valor(item) | currency }}
                              </span>
                              <span v-else>
                                {{ item.valor | currency }}
                              </span>
                            </td>
                            <td>
                              {{ item.subtotal | currency }}
                            </td>
                            <td>{{ item.iva | currency }}</td>
                            <td>
                              <div v-if="item.categoria == 'consumo'">
                                <span>
                                  {{ item.total | currency }}
                                </span>
                                <br />
                                <small>Incluido en el servicio</small>
                              </div>
                              <div v-else>
                                {{ item.total | currency }}
                              </div>
                            </td>
                            <td>
                              {{item.vendedor}}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Servicios -->
              <v-col cols="12" md="12" sm="12" v-if="servicios.length > 0">
                <v-card elevation="0" outlined>
                  <v-card-title>Servicios</v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Servicio</th>
                            <th>Profesional</th>
                            <th>Doc Equivalente</th>
                            <th>Cantidad</th>
                            <th>Subtotal</th>
                            <th>IVA</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in servicios" :key="index">
                            <td>
                              {{ item.salida }}
                            </td>
                            <td>
                              {{ item.profesional.profesional }}
                            </td>
                            <td>
                              {{ item.profesional.folio | folio }}
                            </td>
                            <td>
                              {{ item.cantidad }}
                            </td>
                            <td>
                              {{ item.subtotal | currency }}
                            </td>
                            <td>{{ item.iva | currency }}</td>
                            <td>{{ item.total | currency }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Total -->
              <v-col cols="12" md="5" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Total factura
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>Subtotal</th>
                            <td>
                              {{
                                parseFloat(factura.factura.subtotal) | currency
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>IVA</th>
                            <td>
                              {{ parseFloat(factura.factura.iva) | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Descuento</th>
                            <td>
                              {{ factura.factura.descuento | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              Propina
                            </th>
                            <td>
                              {{ factura.factura.propina | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>
                              {{
                                (parseFloat(factura.factura.total) -
                                  parseFloat(factura.factura.descuento) +
                                  parseFloat(factura.factura.propina))
                                  | currency
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Metodos de pago -->
              <v-col cols="12" md="7" sm="6" v-if="factura.factura.metodo == 1">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    Metodos de pago
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>Efectivo</th>
                            <td>
                              {{ factura.factura.efectivo | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Transferencia</th>
                            <td>
                              {{ factura.factura.transfer | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Datafono</th>
                            <td>
                              {{ factura.factura.datafono | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              Puntos
                            </th>
                            <td>
                              {{ factura.factura.puntos }}
                            </td>
                          </tr>
                          <tr>
                            <th>Bono regalo</th>
                            <td>
                              {{ factura.factura.bono_valor | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Cortesia</th>
                            <td>
                              {{ factura.factura.cortesia | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>Anticipo</th>
                            <td>
                              {{ factura.factura.total_anticipo | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Descuento -->
              <v-col cols="12" md="7" sm="6" v-if="factura.factura.metodo == 2">
                <v-card elevation="0" outlined>
                  <v-card-title>
                    <v-icon left>mdi-percent</v-icon>
                    Aplicar descuento
                    <v-spacer></v-spacer>
                    <v-btn color="black" dark small @click="aplicarDescuento()">
                      Aplicar descuento
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Subtotal</th>
                            <th>Porcentaje</th>
                            <th>Descuento</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              {{
                                parseFloat(factura.factura.subtotal) | currency
                              }}
                            </th>
                            <td>
                              <v-text-field
                                style="width: 200px"
                                type="number"
                                class="mt-2"
                                dense
                                outlined
                                rounded
                                v-model="descuento"
                                label="Descuento"
                                hint="Porcentaje de descuento"
                                persistent-hint
                                @input="descuentoAdd"
                              ></v-text-field>
                            </td>
                            <td>
                              {{ factura.factura.descuento | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="factura.notas.length > 0">
                <v-card elevation="0" outlined>
                  <v-card-title>Notas credito</v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th>Creación</th>
                                <th>N° nota credito</th>
                                <th>Subtotal</th>
                                <th>IVA</th>
                                <th>Total</th>
                                <th>Estado</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in factura.notas"
                                :key="index"
                              >
                                <td>
                                  {{ moment(item.created_at).format("ll") }}
                                </td>
                                <td>
                                  {{ item.folio | folio }}
                                </td>
                                <td>-{{ item.subtotal | currency }}</td>
                                <td>-{{ item.iva | currency }}</td>
                                <td>-{{ item.total | currency }}</td>
                                <td>
                                  <v-chip
                                    small
                                    color="error"
                                    v-if="!item.estado"
                                  >
                                    Rechazada
                                  </v-chip>
                                  <v-chip
                                    small
                                    color="success"
                                    v-if="
                                      (item.estado && item.dian_state) ||
                                        (item.estado && item.folio_dian == null)
                                    "
                                  >
                                    Aprobada
                                  </v-chip>
                                  <v-chip
                                    small
                                    color="primary"
                                    v-if="
                                      item.estado &&
                                        item.dian_state == null &&
                                        item.folio_dian != null
                                    "
                                  >
                                    Pendiente DIAN
                                  </v-chip>
                                </td>
                                <td>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        fab
                                        small
                                        color="primary"
                                        class="my-2"
                                        v-on="on"
                                        v-bind="attrs"
                                        @click="viewNota(item.id)"
                                      >
                                        <v-icon>mdi-text-box-check</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Ver nota credito
                                    </span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- <v-col cols="12">
                <div id="demo"></div>
              </v-col> -->
            </v-row>
            <nota-credito
              :items="items"
              :dialog="dialog"
              v-on:close="close($event)"
              v-on:update="loadDetalleFactura()"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import notaCredito from "../../../components/NotaCredito.vue";
export default {
  name: "detalle",
  components: {
    notaCredito,
  },
  data: () => ({
    factura: {},
    dialog: false,
    descuento: 0,
    items: {},
    loading: false,
    loading_fecha: false,
    response: undefined,
    email: "",
    baseURL: "https://apirestdian.luxcultury.com/api",
    max: moment().format("YYYY-MM-DD"),
  }),
  methods: {
    loadDetalleFactura() {
      const body = {
        route: "/detalle_factura",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.factura = response.data.data;
          parseFloat(this.factura.factura.total);
          this.descuento = Math.round(
            (parseFloat(this.factura.factura.descuento) /
              parseFloat(this.factura.factura.subtotal)) *
              100
          );
          this.email = this.factura.factura.correo;
        }
      });
    },
    valor(value) {
      return parseFloat(value.total) / parseInt(value.cantidad);
    },
    aplicarDescuento() {
      const body = {
        route: "/aplicar_descuento",
        data: {
          descuento: this.factura.factura.descuento,
          id: this.factura.factura.id,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    descuentoAdd(value) {
      if (value == "") {
        value = 0;
      }
      this.factura.factura.descuento = parseFloat(
        (
          (parseFloat(this.factura.factura.subtotal) / 100) *
          parseFloat(value)
        ).toFixed(2)
      );
    },
    notaCredito() {
      if (this.factura.factura.metodo == 2) {
        this.$refs.acciones.isActive = false;
        this.items = this.venta;
        this.dialog = true;
      } else {
        this.$refs.acciones.isActive = false;
        const body = {
          route: "/create_nota_credito_servicios",
          data: {
            id: this.factura.factura.id,
            time: moment().format("HH:mm:ss"),
            date: moment().format("YYYY-MM-DD"),
          },
        };
        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data.data,
              };
              this.$store.dispatch("setAlert", alert);
              if (response.data.data.electronico.authorization) {
                this.sendNotaCredito(response.data.data.electronico);
              }
              this.loadDetalleFactura();
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          });
      }
    },
    sendNotaCredito(data) {
      let url_api = "/ubl2.1/credit-note";
      if (data.test != null) url_api = `${url_api}/${data.test}`;
      axios({
        method: "POST",
        baseURL: this.baseURL,
        url: url_api,
        headers: {
          Authorization: `Bearer ${data.auth_token}`,
        },
        data: data.body,
      })
        .then((response) => {
          let alert = {
            alert: true,
            color: "success",
            text: response.data.message,
          };
          this.$store.dispatch("setAlert", alert);

          let update_nota;

          if (data.test != null) {
            update_nota = {
              folio_dian: data.body.number,
              zip_key:
                response.data.ResponseDian.Envelope.Body
                  .SendTestSetAsyncResponse.SendTestSetAsyncResult.ZipKey,
              cufe: response.data.cude,
              attached: response.data.urlinvoicepdf,
            };
          } else {
            update_nota = {
              folio_dian: data.body.number,
              cufe: response.data.cude,
              attached: response.data.urlinvoicepdf,
            };

            if (
              response.data.ResponseDian.Envelope.Body.SendBillSyncResponse
                .SendBillSyncResult.IsValid == "true"
            ) {
              update_nota = Object.assign(update_nota, { dian_state: true });
            } else {
              update_nota = Object.assign(update_nota, {
                dian_state: false,
                estado: false,
              });
            }
          }

          this.updateNotaCredito(update_nota, data.nota_id);
        })
        .catch((error) => {
          let update_nota = {
            estado: false,
          };
          this.updateNotaCredito(update_nota, data.nota_id);
          this.$store.dispatch("setAlert", {
            alert: true,
            color: "error",
            text: JSON.stringify(error.response.data.errors),
          });
        });
    },
    updateNotaCredito(update, nota) {
      const body = {
        route: "/update_n_electronica",
        data: { update: update, nota: nota },
      };

      this.$store.dispatch("axios_post", body);
    },
    viewNota(id) {
      this.$router.push("/detalleNotaCredito/" + id);
    },
    close(data) {
      this.dialog = data;
    },
    pdf() {
      this.loading = true;
      this.$refs.acciones.isActive = false;
      axios({
        url: "/viewPDF",
        method: "GET",
        params: {
          id: this.factura.factura.id,
        },
        headers: {
          "access-rec": atob(this.$store.state.access_token.access_token).split(
            "-_-"
          )[0],
          SSREC: this.$store.state.access_token.session_start,
        },
        responseType: "blob",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.id = "fileLink";
          fileLink.href = fileURL;
          fileLink.target = "_blank";
          fileLink.setAttribute(
            "open",
            "factura" +
              this.$options.filters.folio(this.factura.factura.folio) +
              ".pdf"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
          document.getElementById("fileLink").remove();
        })
        .finally(() => (this.loading = false));
    },
    send() {
      const body = {
        route: "/sendPDF_factura",
        params: {
          id: this.factura.factura.id,
          email: this.email,
        },
      };
      if (this.email != "") {
        this.$refs.submenu_send.isActive = false;
        this.$refs.acciones.isActive = false;
        this.loading = true;
        this.$store
          .dispatch("get_data", body)
          .then((response) => {
            if (response.data.success) {
              let alert = {
                alert: true,
                color: "success",
                text: response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
            }
          })
          .catch((error) => {
            let alert = {
              alert: true,
              color: "error",
              text: error.response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      }
    },
    print() {
      this.$refs.acciones.isActive = false;
      const route = this.$router.resolve({
        path: "/pos/" + this.factura.factura.id,
      });
      window.open(route.href, "_blank");
    },
    reasignarFecha() {
      this.loading_fecha = true
      const body = {
        route: "/update_fecha_factura",
        data: {
          fecha: this.factura.factura.fecha,
          id: this.factura.factura.id,
        },
      };

      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.$refs.submenu_reasignar.isActive = false;
            this.$refs.acciones.isActive = false;
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        }).finally(() => (this.loading_fecha = false))
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadDetalleFactura();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    profesionales() {
      const profesionales = this.factura.distribucion.map((distri) => {
        let data = distri;
        if (distri.order_list) {
          data["order"] = true;
        } else {
          data["order"] = false;
        }

        if (data.order) {
          data["indexOrder"] = {
            primary: distri.order_list.split(".")[0],
            secondary: distri.order_list.split(".")[1],
          };
        }

        delete data.order_list;
        return data;
      });
      return profesionales;
    },
    servicios() {
      const servicios = this.factura.salida.filter((salida) => {
        return salida.categoria === "servicio";
      });

      let profesionales_lista = [];

      this.profesionales.forEach((pr) => {
        if (pr.order) {
          if (pr.indexOrder.secondary == "1") {
            profesionales_lista.push(pr);
          }
        } else {
          profesionales_lista.push(pr);
        }
      });

      return servicios.map((salida) => {
        salida.subtotal = parseFloat(salida.subtotal);
        salida.iva = parseFloat(salida.iva);
        salida.total = parseFloat(salida.total);

        salida["profesional"] = profesionales_lista.filter((prof) => {
          if (prof.order) return prof.indexOrder.primary == salida.order_list;
          else return prof.servicio_id == salida.servicio_id;
        })[0];
        return salida;
      });
    },
    venta() {
      return this.factura.salida
        .filter((salida) => {
          return salida.categoria === "venta";
        })
        .map((salida) => {
          salida.subtotal = parseFloat(salida.subtotal);
          salida.iva = parseFloat(salida.iva);
          salida.total = parseFloat(salida.total);
          return Object.assign(
            {
              nota_credito: 0,
              valor: this.valor(salida),
            },
            salida
          );
        });
    },
    consumo() {
      const consumo = this.factura.salida.filter((salida) => {
        return salida.categoria === "consumo";
      });

      return consumo.map((c) => {
        c.subtotal = parseFloat(c.subtotal);
        c.iva = parseFloat(c.iva);
        c.total = parseFloat(c.total);
        return c;
      });
    },
    productos() {
      return this.consumo.concat(this.venta);
    },
    reasingState() {
      let result = false;

      const total_notas = this.factura.notas.reduce((index, item) => {
        return index + parseFloat(item.total);
      }, 0);

      if (
        total_notas >= parseFloat(this.factura.factura.total) &&
        !this.factura.header.electronica
      )
        result = true;

      return result;
    },
  },
};
</script>

<style scoped>
p {
  color: black;
}
</style>
