<template>
  <div class="notas-credito">
    <v-dialog v-model="dialog" persistent scrollable max-width="900">
      <v-card>
        <v-card-title>
          Nota credito
        </v-card-title>
        <v-card-text>
          <v-row dense v-if="items.length > 0">
            <!-- Productos -->
            <v-col cols="12" md="12" sm="12">
              <v-card elevation="0" outlined>
                <v-card-title>Productos</v-card-title>
                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Producto</th>
                          <th>Cantidad</th>
                          <th>Cantidad actual</th>
                          <th>Valor</th>
                          <th>Subtotal</th>
                          <th>IVA</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td>{{ item.categoria }}</td>
                          <td>
                            {{ item.salida }}
                          </td>
                          <td>
                            <v-text-field
                              style="width: 150px"
                              type="number"
                              class="mt-2"
                              dense
                              outlined
                              rounded
                              v-model.number="item.nota_credito"
                              label="Cantidad"
                              hint="Cantidad"
                              :min="0"
                              :max="item.cantidad"
                              persistent-hint
                              @change="changeValues(item)"
                            ></v-text-field>
                          </td>
                          <td>
                            {{ item.cantidad }}
                          </td>
                          <td>
                            {{ item.valor | currency }}
                          </td>
                          <td>
                            {{
                              restarCantidad(
                                item.subtotal,
                                calculo(item.subtotal, item.cantidad),
                                item.nota_credito
                              ) | currency
                            }}
                          </td>
                          <td>
                            {{
                              restarCantidad(
                                item.iva,
                                calculo(item.iva, item.cantidad),
                                item.nota_credito
                              ) | currency
                            }}
                          </td>
                          <td>
                            {{
                              restarCantidad(
                                item.total,
                                calculo(item.total, item.cantidad),
                                item.nota_credito
                              ) | currency
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="12">
              <v-alert v-model="alert" type="error">
                {{ txt }}
              </v-alert>
            </v-col>
            <v-col col="6" offset="6" align="right">
              <v-btn text color="primary" @click="$emit('close', false)">
                Cancelar
              </v-btn>
              <v-btn color="primary" @click="generarNota()"
                >Generar nota credito</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "notaCredito",
  props: ["items", "dialog"],
  data: () => ({
    alert: false,
    txt: "",
    baseURL: "https://apirestdian.luxcultury.com/api",
  }),
  methods: {
    calculo(valor, cantidad) {
      return Math.round(parseFloat(valor) / parseInt(cantidad));
    },
    restarCantidad(valor, valor_uni, cantidad) {
      return Math.round(parseFloat(valor) - parseFloat(valor_uni) * cantidad);
    },
    changeValues(item) {
      if (item.nota_credito > parseInt(item.cantidad)) {
        item.nota_credito = parseInt(item.cantidad);
      }
      if (item.nota_credito == "") {
        item.nota_credito = 0;
      }
    },
    generarNota() {
      let salidas = [];
      let id_servicios = [];
      this.items.forEach((venta) => {
        if (venta.nota_credito > 0) {
          let data = {
            salida_id: venta.id,
            categoria: venta.categoria,
            cantidad: venta.nota_credito,
            subtotal: Math.round(
              this.calculo(venta.subtotal, venta.cantidad) * venta.nota_credito
            ),
            iva: Math.round(
              this.calculo(venta.iva, venta.cantidad) * venta.nota_credito
            ),
            total: Math.round(
              this.calculo(venta.total, venta.cantidad) * venta.nota_credito
            ),
          };
          salidas.push(data);
        }
      });
      if (salidas.length == 0) {
        this.txt = "No se descontaron unidades";
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
          this.txt = "";
        }, 2000);
      } else {
        const data = {
          route: "/create_nota_credito",
          data: {
            nota_credito: {
              factura_id: this.items[0].factura_id,
              subtotal: salidas.reduce((i, item) => {
                return i + item.subtotal;
              }, 0),
              iva: salidas.reduce((i, item) => {
                return i + item.iva;
              }, 0),
              total: salidas.reduce((i, item) => {
                return i + item.total;
              }, 0),
            },
            nota_productos: salidas,
            created: moment().format("YYYY-MM-DD HH:mm:ss"),
            time: moment().format("HH:mm:ss"),
            date: moment().format("YYYY-MM-DD"),
            id_servicios,
          },
        };
        this.$store
          .dispatch("axios_post", data)
          .then((response) => {
            if (response.data.success) {
              if (response.data.data.electronico.authorization) {
                this.sendNotaCredito(response.data.data.electronico);
              } else {
                let alert = {
                  alert: true,
                  color: "success",
                  text: response.data.data.data,
                };
                this.$store.dispatch("setAlert", alert);
              }
              this.$emit("update");
              this.$emit("close", false);
            }
          })
          .catch((error) => {
            this.txt = error.response.data.data;
            this.alert = true;
            setTimeout(() => {
              this.alert = false;
              this.txt = "";
            }, 5000);
          });
      }
    },
    sendNotaCredito(data) {
      let url_api = "/ubl2.1/credit-note";
      if (data.test != null) url_api = `${url_api}/${data.test}`;
      axios({
        method: "POST",
        baseURL: this.baseURL,
        url: url_api,
        headers: {
          Authorization: `Bearer ${data.auth_token}`,
        },
        data: data.body,
      })
        .then((response) => {
          let alert = {
            alert: true,
            color: "success",
            text: response.data.message,
          };
          this.$store.dispatch("setAlert", alert);

          let update_nota;

          if (data.test != null) {
            update_nota = {
              folio_dian: data.body.number,
              zip_key:
                response.data.ResponseDian.Envelope.Body
                  .SendTestSetAsyncResponse.SendTestSetAsyncResult.ZipKey,
              cufe: response.data.cude,
              attached: response.data.urlinvoicepdf,
            };
          } else {
            update_nota = {
              folio_dian: data.body.number,
              cufe: response.data.cude,
              attached: response.data.urlinvoicepdf,
            };

            if (
              response.data.ResponseDian.Envelope.Body.SendBillSyncResponse
                .SendBillSyncResult.IsValid == "true"
            ) {
              update_nota = Object.assign(update_nota, { dian_state: true });
            } else {
              update_nota = Object.assign(update_nota, {
                dian_state: false,
                estado: false,
              });
            }
          }

          this.updateNotaCredito(update_nota, data.nota_id);
        })
        .catch((error) => {
          this.$store.dispatch("setAlert", {
            alert: true,
            color: "error",
            text: JSON.stringify(error.response.data.errors),
          });
        });
    },
    updateNotaCredito(update, nota) {
      const body = {
        route: "/update_n_electronica",
        data: { update: update, nota: nota },
      };

      this.$store.dispatch("axios_post", body);
    },
  },
};
</script>
